require(['jquery', 'parsleyjs'], function ($) {


  var toBeValidatedForms = $('[data-parsley-validate]');

  toBeValidatedForms.on('submit', function (e) {
    if (!$(this).parsley().isValid()) {
      e.preventDefault();
      return false;
    }
  });
});

define("parsleyNewImpl", function(){});

